import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { t } from "i18next";
import { Fragment } from "react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type VibeType =
  | "Candid"
  | "Professional"
  | "Humorous"
  | "Empathetic"
  | "Persuasive"
  | "Assertive"
  | "Inspirational"
  | "Instructional"
  | "Collaborative"
  | "Diplomatic";

interface DropDownProps {
  vibe: VibeType;
  setVibe: (vibe: VibeType) => void;
}

const vibes: VibeType[] = [
  "Candid",
  "Professional",
  "Humorous",
  "Empathetic",
  "Persuasive",
  "Assertive",
  "Inspirational",
  "Instructional",
  "Collaborative",
  "Diplomatic",
];
const vibeDescriptions: string[] = [
  t("dropdown.candid-description") ??
    "Open, honest, and direct communication, without sugarcoating the truth.",
  t("dropdown.professional-description") ??
    "Formal, respectful, and polished language, adhering to established guidelines and conventions.",
  t("dropdown.humorous-description") ??
    "Light-hearted and witty remarks to engage the audience and create a relaxed atmosphere.",
  t("dropdown.empathetic-description") ??
    "Understanding and compassionate tone, expressing concern and consideration for others' feelings.",
  t("dropdown.persuasive-description") ??
    "Convincing and influential, with a focus on presenting logical arguments and reasons.",
  t("dropdown.assertive-description") ??
    "Confident and decisive, clearly stating one's opinions, beliefs, or needs without being aggressive.",
  t("dropdown.inspirational-description") ??
    "Motivating and uplifting, encouraging others to take action or believe in themselves.",
  t("dropdown.instructional-description") ??
    "Clear and concise, providing step-by-step guidance or explanations to help others understand and learn.",
  t("dropdown.collaborative-description") ??
    "Inviting, supportive, and encouraging teamwork, fostering a sense of unity and shared goals.",
  t("dropdown.diplomatic-description") ??
    "Tactful and respectful, navigating sensitive topics or situations with grace and neutrality.",
];

export default function DropDown({ vibe, setVibe }: DropDownProps) {
  return (
    <Menu as="div" className="relative block text-left w-full">
      <div>
        <Menu.Button className="inline-flex w-full justify-between items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black dark:border-gray-800 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-500 dark:text-slate-300">
          {t(`dropdown.${vibe.toLowerCase()}`)}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 hidden ui-open:block"
            aria-hidden="true"
          />
          <ChevronUpIcon
            className="-mr-1 ml-2 h-5 w-5 ui-open:hidden"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-0 z-50 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          key={vibe}
        >
          <div className="">
            {vibes.map((vibeItem, index) => (
              <Menu.Item key={vibeItem}>
                {({ active }) => (
                  <div className="relative group">
                    <button
                      onClick={() => setVibe(vibeItem)}
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-slate-600 dark:text-slate-300"
                          : "text-gray-700",
                        vibe === vibeItem
                          ? "bg-gray-200 dark:bg-slate-600 dark:text-slate-300"
                          : "",
                        "px-4 py-2 text-sm w-full text-left flex items-center space-x-2 justify-between dark:bg-slate-700 dark:text-slate-300"
                      )}
                    >
                      <span>{t(`dropdown.${vibeItem.toLowerCase()}`)}</span>
                      {vibe === vibeItem ? (
                        <CheckIcon className="w-4 h-4 text-bold" />
                      ) : null}
                    </button>
                    <div className="w-36 sm:w-52 p-6 tracking-tight antialiased font-medium text-left absolute invisible z-10 group-hover:visible text-sm text-slate-100 py-1 px-2 rounded shadow-lg border border-gray-300 -mt-9 -ml-40 sm:-ml-56 bg-gradient-to-r from-sky-600 via-cyan-600 to-teal-600 bg-clip-padding">
                      {vibeDescriptions[index]}
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
