import { Menu, Transition } from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { t } from "i18next"

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export type Language = "Arabic" | "Chinese" | "Dutch" | "English" | "French" | "German" | "Korean" | "Italian" | "Portuguese" | "Spanish" | "Turkish" | "Vietnamese";

interface LanguageDropDownProps {
    language: Language;
    setLanguage: (language: Language) => void;
}

const languages: Language[] = ["Arabic", "Chinese", "Dutch", "English", "French", "German", "Italian", "Korean", "Portuguese", "Spanish", "Turkish", "Vietnamese"];

export default function LanguageDropDown({ language, setLanguage }: LanguageDropDownProps) {
    return (
        <Menu as="div" className="relative block text-left w-full">
            <div>
                <Menu.Button className="inline-flex w-full justify-between items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black dark:border-gray-800 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-500 dark:text-slate-300">
                    {t(`dropdown.${language.toLowerCase()}`)}
                    <ChevronUpIcon
                        className="-mr-1 ml-2 h-5 w-5 ui-open:hidden"
                        aria-hidden="true"
                    />
                    <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5 hidden ui-open:block"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition

                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700 dark:ring-slate-500 dark:text-slate-300"
                    key={language}
                >
                    <div className="">
                        {languages.map((languageItem) => (
                            <Menu.Item key={languageItem}>
                                {({ active }) => (
                                    <button
                                        onClick={() => setLanguage(languageItem)}
                                        className={classNames(
                                            active ? "bg-gray-100 text-gray-900 dark:bg-slate-600 dark:text-slate-300" : "text-gray-700",
                                            language === languageItem ? "bg-gray-200 dark:bg-slate-600 dark:text-slate-300" : "",
                                            "px-4 py-2 text-sm w-full text-left flex items-center space-x-2 justify-between dark:text-slate-300"
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                language === languageItem ? "font-medium" : "font-normal",
                                                "truncate"
                                            )}
                                        >
                                                     {t(`dropdown.${languageItem.toLowerCase()}`)}
                                        </span>
                                        <div className="ml-4 flex-row flex">
                                        {language === languageItem ? (
                                            <CheckIcon
                                                className="h-5 w-5 ml-3 text-slate-500"
                                                aria-hidden="true"
                                            />
                                        ) : null}
                                        </div>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}