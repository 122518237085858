import { useState, useEffect } from 'react';

type UseGeneratedEmailCountType = {
  emailCount: string;
  fetchEmailCount: () => Promise<void>;
};

export const useGeneratedEmailCount = (): UseGeneratedEmailCountType => {
  const [emailCount, setEmailCount] = useState<string>('');

  const fetchEmailCount = async () => {
    const response = await fetch("/api/get-total-count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { count } = await response.json();
    setEmailCount(count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  };

  useEffect(() => {
    fetchEmailCount();
  }, []);

  return {
    emailCount,
    fetchEmailCount,
  };
};
