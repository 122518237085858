import mixpanel from 'mixpanel-browser';

export const initMixpanel = (key: string) => {
  mixpanel.init(key);
};

export const trackEvent = (
  eventName: string,
  eventData: { [key: string]: any }
) => {
  mixpanel.track(eventName, eventData);
};