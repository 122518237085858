import { publicIpv4 } from 'public-ip';
import { useState, useEffect } from 'react';

type UseDailyLimitType = {
  limit: number;
  isLimitLoaded: boolean;
  fetchDailyLimit: () => Promise<void>;
};

export const useDailyLimit = (): UseDailyLimitType => {
  const [limit, setLimit] = useState<number>(0);
  const [isLimitLoaded, setIsLimitLoaded] = useState<boolean>(false);

  const fetchDailyLimit = async () => {
    const response = await fetch("/api/get-daily-limit", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Forwarded-For": await publicIpv4(),
      },
    });
    const data = await response.json();
    setLimit(data.limit);
    setIsLimitLoaded(true);
  };

  useEffect(() => {
    fetchDailyLimit();
  }, []);

  return {
    limit,
    isLimitLoaded,
    fetchDailyLimit,
  };
};
