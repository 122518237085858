import { motion } from "framer-motion";
import MessageTypeDropDown, {
  MessageType,
} from "../Dropdowns/MessageTypeDropDown";
import DropDown, { VibeType } from "../Dropdowns/DropDown";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import LengthDropDown, { Length } from "../Dropdowns/LengthDropDown";
import GenerateButton from ".././Hero/GenerateButton";
import { t } from "i18next";
import { RefObject, Dispatch, SetStateAction, useState } from "react";
import { isUnlimitedUser } from "../../utils/main-functionality";
import useUpgradeToPremium from "../../hooks/useUpgradeToPremium";
import { auth } from "../../utils/Authentication";
import SignInPopup from "../Core/SignInPopup";

interface FormAreaProps {
  emailInputRef: RefObject<HTMLTextAreaElement>;
  setEmail: Dispatch<SetStateAction<string>>;
  messageType: MessageType; // Eğer MessageType tipiniz varsa onu kullanın
  setMessageType: Dispatch<SetStateAction<MessageType>>;
  vibe: VibeType; // Vibe tipiniz varsa onu kullanın
  setVibe: Dispatch<SetStateAction<VibeType>>;
  language: Language; // Language tipiniz varsa onu kullanın
  setLanguage: Dispatch<SetStateAction<Language>>;
  length: Length; // Length tipiniz varsa onu kullanın
  setLength: Dispatch<SetStateAction<Length>>;
  setUseEmojis: Dispatch<SetStateAction<boolean>>;
  useGPT4: boolean;
  setUseGPT4: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  generateEmail: any;
}

const FormArea: React.FC<FormAreaProps> = ({
  emailInputRef,
  setEmail,
  messageType,
  setMessageType,
  vibe,
  setVibe,
  language,
  setLanguage,
  length,
  setLength,
  setUseEmojis,
  useGPT4,
  setUseGPT4,
  loading,
  generateEmail,
}) => {
  const { isPopupOpen, togglePopup } = useUpgradeToPremium();

  return (
    <div>
      <SignInPopup isOpen={isPopupOpen} setIsOpen={togglePopup} />
      <motion.textarea
        initial="hidden"
        animate="show"
        value={emailInputRef.current?.value}
        ref={emailInputRef}
        onChange={(e) => setEmail(e.target.value)}
        rows={6}
        className="w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black my-5 dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
        placeholder={t("try-now.placeholder") ?? ""}
      />
      <div className="block mb-5 grid grid-cols-2 sm:gap-6 gap-2 z-0">
        <div className="flex flex-col justify-items-start items-start self-start justify-start">
          <p className="text-left mb-2 font-bold antialiased dark:text-slate-300">
            {t("try-now.type")}
          </p>
          <MessageTypeDropDown
            messageType={messageType}
            setMessageTypes={setMessageType}
          />
        </div>
        <div className="flex flex-col justify-items-start items-start self-start justify-start">
          <p className="text-left mb-2 font-bold antialiased dark:text-slate-300">
            {t("try-now.tone")}
          </p>
          <DropDown vibe={vibe} setVibe={setVibe} />
        </div>
      </div>
      <div className="block mb-5 grid grid-cols-2 sm:gap-6 gap-2">
        <div className="flex flex-col justify-items-start items-start self-start justify-start">
          <p className="text-left mb-2 font-bold antialiased dark:text-slate-300">
            {t("try-now.language")}
          </p>
          <LanguageDropDown language={language} setLanguage={setLanguage} />
        </div>
        <div className="flex flex-col justify-items-start items-start self-start justify-start">
          <p className="text-left mb-2 font-bold antialiased dark:text-slate-300">
            {t("try-now.length")}
          </p>
          <LengthDropDown length={length} setLength={setLength} />
        </div>
      </div>
      <motion.div
        initial="hidden"
        animate="show"
        className="flex items-center mb-5"
      >
        <input
          onClick={() => setUseEmojis((prev: any) => !prev)}
          type="checkbox"
          id="use-emojis"
          name="use-emojis"
          className="rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
        />
        <label htmlFor="use-emojis" className="flex items-center ml-3">
          <span className="block text-sm font-medium text-gray-700 dark:text-slate-300">
            {t("try-now.use-emojis")}
          </span>
        </label>
        <div className="relative flex items-center ml-3">
          <input
            onClick={() => {
              if (
                !auth.currentUser ||
                (auth.currentUser &&
                  auth.currentUser.email &&
                  !isUnlimitedUser(auth.currentUser.email))
              ) {
                togglePopup();
                return;
              }

              setUseGPT4(!useGPT4);
            }}
            type="checkbox"
            checked={useGPT4}
            id="use-gpt4"
            name="use-gpt4"
            className="ml-5 rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
          />
          <label htmlFor="use-gpt4" className="flex items-center ml-3">
            <span className="block text-sm font-medium text-gray-700 dark:text-slate-300">
              {t("try-now.use-gpt4")}
            </span>
          </label>
        </div>
      </motion.div>
      <GenerateButton
        loading={loading}
        messageType={messageType}
        generateEmail={generateEmail}
      />
    </div>
  );
};

export default FormArea;
