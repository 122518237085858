import { useState } from "react";
import { ParsedEvent, ReconnectInterval } from "eventsource-parser"; // import'lara dikkat edin, tam olarak nereden geldiklerini bilmiyorum
import { generateEmailService } from "../services/generateEmailService";

type UseEmailGenerationProps = {
    emailInput: string;
    vibe: string;
    messageType: string;
    language: string;
    length: string;
    useEmojis: boolean;
    useGPT4: boolean;
    user: string | null;
};

const useEmailGeneration = ({ emailInput, vibe, messageType, language, length, useEmojis, useGPT4, user }: UseEmailGenerationProps) => {
    const [generatedEmail, setGeneratedEmail] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [isDone, setIsDone] = useState(false);  // Yeni state

    const createEmail = async () => {
        try {
            setIsGenerating(true); // İşlem başladığında
            const result = await generateEmailService({
                emailInput,
                vibe,
                messageType,
                language,
                length,
                useEmojis,
                useGPT4,
                user,
                onParse: (event: ParsedEvent | ReconnectInterval) => {
                    if (event.type === "event") {
                        const data = event.data;
                        try {
                            const text = JSON.parse(data).text ?? "";
                            setGeneratedEmail((prev) => prev + text);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                },
            });

            if (result === 'Done') {
                setIsDone(true);  // İşlem tamamlandığında
            }
        } finally {
            setIsGenerating(false);  // İşlem tamamlandı ya da bir hata oluştu
        }
    };

    return { createEmail, generatedEmail, setGeneratedEmail, isDone };  // isDone'u döndür
};


export default useEmailGeneration;
