
import React from 'react';
import {t} from 'i18next';
import { useState } from 'react';
import Image from 'next/image';

interface RateItProps {
    message: string;
    user: string;
    isSelected: boolean;
    setSelected: Function;
}

const RateIt = ({message, user, isSelected, setSelected}: RateItProps) => {

    const handleSelect = async (e: React.MouseEvent<HTMLDivElement>) => {
        setSelected(!isSelected);

        const selectedValue = e.currentTarget.getAttribute('selected-value');

        if (selectedValue === '1') {
            await fetch('/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    rate: 'Awesome',
                    user,
                    message
                }),
            });
        }

        if (selectedValue === '2') {
            await fetch('/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    rate: 'Neutral',
                    user,
                    message
                }),
            });
        }

        if (selectedValue === '3') {
            await fetch('/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    rate: 'Bad',
                    user,
                    message
                }),
            });
        }
    }

    return (
        <>
        { !isSelected ? (
            <div className="py-12 bg-white">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-sm mx-auto">
                    <p className="text-sm font-bold text-gray-900">{t('feedback.did-you-like-it')}</p>

                    <div className="mt-4 overflow-auto bg-white border border-gray-200 divide-y divide-gray-200 rounded-xl">
                        <div className="p-4 transition-all duration-200 bg-white cursor-pointer hover:bg-gray-50" onClick={handleSelect} selected-value="1">
                            <div className="flex items-center justify-between">
                                <Image src="/awesome.webp" className="flex-shrink-0 object-cover rounded-full w-9 h-9" alt="Awesome" width={32} height={32} />
                                <div className="flex-1 min-w-0 ml-4">
                                    <p className="text-sm font-bold text-gray-900 truncate">{t('feedback.it-is-awesome')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="p-4 transition-all duration-200 bg-white cursor-pointer hover:bg-gray-50" onClick={handleSelect} selected-value="2">
                            <div className="flex items-center justify-between">
                                <Image src="/neutral.webp" className="flex-shrink-0 object-cover rounded-full w-9 h-9" alt="Neutral" width={32} height={32} />
                                <div className="flex-1 min-w-0 ml-4">
                                <p className="text-sm font-bold text-gray-900 truncate">{t('feedback.it-is-okay')}</p>
                                </div>
                                <svg className="w-6 h-6 ml-4 text-indigo-600 opacity-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>

                        <div className="p-4 transition-all duration-200 bg-white cursor-pointer hover:bg-gray-50" onClick={handleSelect} selected-value="3">
                            <div className="flex items-center justify-between">
                                <Image src="/sad.webp" className="flex-shrink-0 object-cover rounded-full w-9 h-9" alt="Sad" width={32} height={32} />
                                <div className="flex-1 min-w-0 ml-4">
                                <p className="text-sm font-bold text-gray-900 truncate">{t('feedback.it-is-bad')}</p>
                                </div>
                                <svg className="w-6 h-6 ml-4 text-indigo-600 opacity-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> ) : (
            <div className="pt-6 -mb-6 bg-white">
                    <p className="text-md text-center font-bold text-gray-900">{t('feedback.thanks')}</p>
            </div>
        )}
        </>
        );
};

export default RateIt;