import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { t } from "i18next";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type Length = "Short" | "Medium" | "Long";

interface DropDownProps {
  length: Length;
  setLength: (length: Length) => void;
}

const lengths: Length[] = ["Short", "Medium", "Long"];

export default function DropDown({ length, setLength }: DropDownProps) {
  return (
    <Menu as="div" className="relative block text-left w-full">
      <div>
        <Menu.Button className="inline-flex w-full justify-between items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black dark:border-gray-800 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-500 dark:text-slate-300">
          {t(`dropdown.${length.toLowerCase()}`)}
          <ChevronUpIcon
            className="-mr-1 ml-2 h-5 w-5 ui-open:hidden"
            aria-hidden="true"
          />
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 hidden ui-open:block"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-100 dark:ring-slate-500"
          key={length}
        >
          <div className="">
            {lengths.map((lengthItem: any) => (
              <Menu.Item key={lengthItem}>
                {({ active }) => (
                  <div className="relative group">
                    <button
                      onClick={() => setLength(lengthItem)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900 dark:bg-slate-600 dark:text-slate-300" : "text-gray-700",
                        length === lengthItem ? "bg-gray-200 dark:bg-slate-600 dark:text-slate-300" : "",
                        "px-4 py-2 text-sm w-full text-left flex items-center space-x-2 justify-between dark:bg-slate-700 dark:text-slate-300"
                      )}
                    >
                      <span>{t(`dropdown.${lengthItem.toLowerCase()}`)}</span>
                      {length === lengthItem ? (
                        <CheckIcon className="w-4 h-4 text-bold" />
                      ) : null}
                    </button>
                  </div>)}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
