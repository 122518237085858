import { publicIpv4 } from "public-ip";
import { useState, useEffect } from "react";

export const useIp = () => {
    const [ipAddress, setIpAddress] = useState("");
    
    useEffect(() => {
      publicIpv4().then(setIpAddress);
    }, []);
    
    return ipAddress;
  };