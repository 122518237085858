import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import { auth } from "../../utils/Authentication";
import { publicIpv4 } from "public-ip";
import { DefaultTFuncReturn, t } from "i18next";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";
import { Marked, Renderer } from "@ts-stack/markdown";
import mixpanel from "mixpanel-browser";
import {
  createParser,
  ParsedEvent,
  ReconnectInterval,
} from "eventsource-parser";
import { useS3Upload } from "../../hooks/useS3Upload";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";

Marked.setOptions({
  renderer: new Renderer(),
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});

AWS.config.update({
  accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
  region: process.env.NEXT_PUBLIC_AWS_REGION,
  signatureVersion: "v4",
});

export default function SignIn() {
  const [pdfText, setPdfText] = useState<string>("");
  const [coverLetter, setCoverLetter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<Language>("English");
  const [positionTitle, setPositionTitle] = useState<string>("");
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("0.0.0.0");
  const { isUploadLoading, s3Url, uploadError, uploadToS3 } = useS3Upload();

  GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js";
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");

  useEffect(() => {
    if (coverLetter.includes("[DONE]")) {
      setCoverLetter(coverLetter.replace("[DONE]", ""));
    }
  }, [coverLetter]);

  const isUnlimitedUser = async () => {
    await publicIpv4().then((ip) => setIpAddress(ip));
    if (auth.currentUser?.email) {
      const response = await fetch("/api/user/type", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: auth.currentUser,
        }),
      });

      const { isUnlimitedUser } = await response.json();

      setUnlimitedUser(isUnlimitedUser);
    }
  };

  useEffect(() => {
    isUnlimitedUser();
  }, []);

  const [file, setFile] = useState<File | null>(null);
  const [dropRejected, setDropRejected] = useState<boolean>(false);
  const [dropAccepted, setDropAccepted] = useState<boolean>(false);
  const [dropText, setDropText] = useState<DefaultTFuncReturn | string>(
    t("cover-letter-generator.drop-text")
  );

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDropText(t("cover-letter-generator.drop-your-cv-here"));
    e.stopPropagation();
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const handleDragExit = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDropText(t("cover-letter-generator.drop-text"));
    e.stopPropagation();
  };

  const handleReject = () => {
    setDropRejected(true);
    setDropText(t("cover-letter-generator.drop-text"));
  };

  const uploadFile = async () => {
    if (!file) {
      return;
    }

    await uploadToS3(file, "cover-letter");
  };

  const generateCoverLetter = async (s3Url: string) => {
    const loadingTask = getDocument(s3Url);
    const pdfDocument = await loadingTask.promise;
    const numPages = pdfDocument.numPages;

    let pdfText = "";

    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      const page = await pdfDocument.getPage(pageNumber);
      const content = await page.getTextContent();

      const strings = content.items.map((item: any) => item.str);
      pdfText += strings.join(" ") + "\n";
    }

    const response = await fetch("/api/cover-letter", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pdfText,
        language,
        positionTitle,
        user: auth.currentUser,
        ipAddress,
      }),
    });

    if (response.status === 413) {
      toast.error(
        "The CV you entered is too long. Please try again with a shorter CV."
      );
      setLoading(false);
      return;
    }

    if (response.ok) {
      // This data is a ReadableStream
      const data = response.body;
      if (!data) {
        return;
      }

      const onParse = (event: ParsedEvent | ReconnectInterval) => {
        if (event.type === "event") {
          const data = event.data;
          try {
            const text = JSON.parse(data).text ?? "";
            setCoverLetter((prev) => prev + text);
          } catch (e) {
            console.error(e);
          }
        }
      };

      // https://web.dev/streams/#the-getreader-and-read-methods
      const reader = data.getReader();
      const decoder = new TextDecoder();
      const parser = createParser(onParse);
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        parser.feed(chunkValue);

        if (done) {
          mixpanel.track("Cover Letter Generated", {
            "Position Title": positionTitle,
            Language: language,
            "IP Address": ipAddress,
            "Unlimited User": isUnlimited,
            "PDF Content": pdfText,
          });
        }
      }
      setDropAccepted(false);
    } else {
      setPdfText("Error retrieving the PDF text.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (file) {
      if (file?.type !== "application/pdf") {
        handleReject();
        setFile(null);
      } else {
        uploadFile();
        setDropAccepted(true);
        setDropText(t("cover-letter-generator.upload"));
      }
    }
  }, [file]);

  useEffect(() => {
    if (s3Url) {
      generateCoverLetter(s3Url);
    }
  }, [s3Url]);

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <main className="w-full">
        <div className="relative sm:mx-auto">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full sm:mx-auto rotate-180 opacity-30 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            ></div>
          </div>
          <div className="p-8 sm:px-10 relative bg-white shadow-xl rounded-2xl">
            <div className="space-y-5">
              {!isUploadLoading && !coverLetter ? (
                <>
                  <div className="block mt-5 w-full">
                    <div className="flex mb-5 items-center space-x-3">
                      <div className="flex items-center justify-center w-8 h-8 font-black rounded-full bg-slate-900 text-white  dark:text-slate-800 dark:bg-slate-300">
                        1
                      </div>
                      <p className="text-left font-bold antialiased dark:text-slate-300">
                        {t("cover-letter-generator.first-step")}
                      </p>
                    </div>
                    <LanguageDropDown
                      language={language}
                      setLanguage={(newLanguage) => setLanguage(newLanguage)}
                    />
                  </div>
                  <div className="block mt-5 w-full">
                    <div className="flex mb-5 items-center space-x-3">
                      <div className="flex items-center justify-center w-8 h-8 font-black rounded-full bg-slate-900 text-white  dark:text-slate-800 dark:bg-slate-300">
                        2
                      </div>
                      <p className="text-left font-bold antialiased dark:text-slate-300">
                        {t("cover-letter-generator.second-step")}{" "}
                        <span className="text-slate-400 dark:text-slate-600">
                          {t("cover-letter-generator.second-step-description")}
                        </span>
                      </p>
                    </div>
                    <input
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
                      type="text"
                      placeholder="Position title"
                      value={positionTitle}
                      onChange={(e) => setPositionTitle(e.target.value)}
                    />
                  </div>
                  <div className="block mt-5 w-full">
                    <div className="flex mb-5 items-center space-x-3">
                      <div className="flex items-center justify-center w-8 h-8 font-black rounded-full bg-slate-900 text-white  dark:text-slate-800 dark:bg-slate-300">
                        3
                      </div>
                      <p className="text-left font-bold antialiased dark:text-slate-300">
                        {t("cover-letter-generator.third-step")}
                      </p>
                    </div>
                    {isUploadLoading && !coverLetter ? (
                      <p>{t("cover-letter-generator.upload")}</p>
                    ) : (
                      <>
                        {dropRejected && (
                          <p className="border border-red-500 text-red-500 p-2 rounded-md my-12 w-1/2 mx-auto">
                            {t("cover-letter-generator.unsupported-file-type")}
                          </p>
                        )}
                        <Dropzone
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragExit}
                          multiple={false}
                          maxSize={1000000}
                          onDropRejected={() => handleReject()}
                          onDropAccepted={() => setDropRejected(false)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps()}
                              className="cursor-pointer border h-24 flex flex-col justify-center items-center rounded-md border-dashed border-gray-800 dark:border-gray-500"
                            >
                              <input {...getInputProps()} />
                              <p
                                className={`text-lg text-center text-slate-500 dark:text-slate-300 ${
                                  dropAccepted ? "text-green-800" : ""
                                }`}
                              >
                                {dropText}
                              </p>
                            </div>
                          )}
                        </Dropzone>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h1 className="text-4xl font-bold text-center mb-5 text-slate-500 dark:text-slate-300">
                    {t("cover-letter-generator.your-cover-letter")}
                  </h1>
                  <p className="text-lg text-center mb-2 text-slate-500 dark:text-slate-300">
                    {t(
                      "cover-letter-generator.generated-cover-letter-description"
                    )}
                    <br />
                    {t(
                      "cover-letter-generator.generated-cover-letter-description-2"
                    )}
                  </p>
                </>
              )}
              {loading && (
                <button
                  className="bg-black rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-black/80 w-full"
                  disabled
                >
                  <LoadingDots color="white" style="large" />
                </button>
              )}
            </div>
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{ duration: 2000 }}
            />
            <ResizablePanel>
              <AnimatePresence mode="wait">
                <motion.div className="space-y-10 my-10">
                  {coverLetter && (
                    <>
                      <div className="space-y-4 flex flex-col items-center justify-center max-w-xl mx-auto">
                        <div
                          className="bg-white rounded-xl text-left shadow-md p-8 hover:bg-gray-100 transition cursor-copy border dark:bg-slate-300"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              String(coverLetter).replace(
                                /<br\s*[\/]?>/gi,
                                "\n"
                              )
                            );
                            toast("Cover letter copied to clipboard", {
                              icon: "✂️",
                            });
                          }}
                        >
                          <div
                            className="text-slate-900 font-semibold antialiased flex flex-col generated-content"
                            dangerouslySetInnerHTML={{
                              __html: Marked.parse(
                                String(coverLetter).replace(
                                  /<br\s*[\/]?>/gi,
                                  "\n"
                                )
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </AnimatePresence>
            </ResizablePanel>
          </div>
        </div>
      </main>
    </motion.div>
  );
}
