import { motion } from "framer-motion";
import { FC } from "react";
import LoadingDots from "../Core/LoadingDots"; // Yükleme noktalarının olduğu dosyayı import edin
import { t } from "i18next";

interface GenerateButtonProps {
  loading: boolean;
  messageType: string;
  generateEmail: (e: any) => void;
}

const GenerateButton: FC<GenerateButtonProps> = ({
  loading,
  messageType,
  generateEmail,
}) => {
  return !loading ? (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      className="generate-button bg-gradient-to-br from-teal-900 via-black to-teal-800 rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-4 mt-2 hover:bg-black/80 w-full dark:bg-slate-300 dark:text-slate-800 dark:hover:bg-slate-500"
      onClick={(e: any) => generateEmail(e)}
    >
      {t("try-now.generate", { type: messageType })}
    </motion.button>
  ) : (
    <button
      className="generate-button bg-gradient-to-br from-black via-teal-800 to-black rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-4 mt-2 hover:bg-black/80 w-full dark:bg-slate-300 dark:text-slate-800 dark:hover:bg-slate-500"
      disabled
    >
      <LoadingDots color="white" style="large" />
    </button>
  );
};

export default GenerateButton;
