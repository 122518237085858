import { useState } from "react";
import axios from "axios";

type TranscriptionResponse = {
  text: string;
  srtUrl?: string;
};

export const useAudioTranscription = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState<TranscriptionResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const transcribeAudio = async (
    s3Url: string,
    translate: boolean,
    srtRequested: boolean
  ) => {
    setLoading(true);

    try {
      const res = await axios.post("/api/transcribe-audio", {
        s3Url,
        translate,
        srtRequested,
      });
      setResponse(res.data);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error("An unknown error occurred."));
      }
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, error, transcribeAudio };
};
