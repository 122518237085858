import { isDailyLimitExceeded } from "../utils/main-functionality";

export const useDailyLimitCheck = (ipAddress: string, unlimitedUser: boolean) => {
    const checkDailyLimit = async (): Promise<boolean> => {
      if (!unlimitedUser && ipAddress) {
        const isExceededResult = await isDailyLimitExceeded(ipAddress);
        if (isExceededResult) {
          return true;
        }
      }
      return false;
    };
  
    return { checkDailyLimit };
  };
  
