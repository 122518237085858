import React from "react";
import { t } from "i18next";
import { motion } from "framer-motion";
import {
  FADE_DOWN_ANIMATION_VARIANTS,
  FADE_UP_ANIMATION_VARIANTS,
} from "../../enums/FramerEnums";
import Review from "./Review";
import Image from "next/image";

const MainLeft = () => {
  return (
    <div className="xl:col-span-1 lg:text-left md:px-16 lg:px-0 xl:pr-20 mb-24 md:mb-0">
      <motion.div initial="hidden" animate="show" viewport={{ once: true }}>
        <motion.h1
          className="text-left bg-gradient-to-r from-teal-800 via-black to-teal-800 inline-block text-transparent bg-clip-text text-xl font-bold leading-tight text-gray-900 sm:text-xl sm:leading-tight lg:text-5xl lg:leading-tight font-pj"
          variants={FADE_UP_ANIMATION_VARIANTS}
        >
          {t("hero.title")}
        </motion.h1>
        <motion.p
          className="mt-2 text-lg text-justify text-gray-600 sm:mt-6 font-inter"
          variants={FADE_UP_ANIMATION_VARIANTS}
        >
          {t("hero.description")}
        </motion.p>
        <motion.div
          className="xl:col-span-1"
          variants={FADE_DOWN_ANIMATION_VARIANTS}
        >
          <Image
            className="w-full mx-auto mt-8 sm:mt-12 lg:mt-8 xl:mt-8"
            width={600}
            height={600}
            src={t("hero.image") ?? "/illustration.webp"}
            blurDataURL={"/illustration-tr-blur.webp"}
            alt="Example"
            priority={true}
            placeholder={"blur"}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MainLeft;
