import { useState } from "react";

const useUpgradeToPremium = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return { isPopupOpen, togglePopup };
};

export default useUpgradeToPremium;
