import { useEffect, useState } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../../initFirebase';
import { t } from 'i18next';

interface CreditDisplayProps {
    ip: string;
}

export const CreditDisplay: React.FC<CreditDisplayProps> = ({ ip }) => {
    const [credit, setCredit] = useState<number | null>(null);
    const calculateRemainingCredit = (credit: number | null) => {
        if (credit === null) return null;
        return credit === 2 ? 0 : credit === 1 ? 1 : 2;
    }
    const remaining = calculateRemainingCredit(credit);

    useEffect(() => {
        const docRef = doc(firestore, 'rateLimits', ip);

        // Realtime Update'i dinle
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setCredit(data?.transcriptionLimit);
            }
        });

        // Komponent unmount olduğunda dinlemeyi durdur
        return () => unsubscribe();
    }, [ip]);

    return (
        <>
            <div className="flex flex-row pb-5 items-center justify-center">
                <div className="ml-1">
                    {credit !== null ? (
                        <p className="font-bold text-sm">{t('audio-transcriptor.credits-used', { credit, remaining })}</p>
                    ) : (
                        <div className="animate-pulse bg-gray-300 h-4 w-48 rounded"></div>
                    )}
                </div>
            </div>
        </>
    );
};
