import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import FormulaDropDown, { Formula } from "../Dropdowns/FormulaDropdown";
import { auth } from "../../utils/Authentication";
import { publicIpv4 } from "public-ip";
import { sendFormula } from "../../utils/main-functionality";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import SignInPopup from "../Core/SignInPopup";
import { t } from "i18next";
import {
  ParsedEvent,
  ReconnectInterval,
  createParser,
} from "eventsource-parser";
import { Marked, Renderer } from "@ts-stack/markdown";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";

Marked.setOptions({
  renderer: new Renderer(),
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});

export default function SignIn() {
  const [pdfText, setPdfText] = useState<string>("");
  const [generatedFormula, setGeneratedFormula] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const messageRef = useRef<HTMLHeadingElement>(null);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [formula, setFormula] = useState<Formula>("Microsoft Excel");
  const formulaInputRef = useRef<HTMLTextAreaElement>(null);
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [language, setLanguage] = useState<Language>("English");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const generateFormula = async (e: any) => {
    e.preventDefault();

    if (!auth.currentUser) {
      toast.error(t("toaster.sign-up-for-use-this-feature"));
      setIsPopupOpen(true);
      return;
    }

    if (prompt.length === 0) {
      toast.error(t("toaster.enter-a-prompt"));
      return;
    }
    if (prompt.length > 1000) {
      toast.error(t("toaster.prompt-too-long"));
      return;
    }

    setGeneratedFormula("");
    const response = await fetch("/api/formula-generator", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        formulaType: formula,
        prompt,
        language,
        user: auth.currentUser,
        ipAddress,
      }),
    });

    if (response.status === 413) {
      toast.error(t("toaster.prompt-too-long"));
      setLoading(false);
      setIsUploaded(false);
      return;
    }

    if (response.ok) {
      const data = response.body;
      if (!data) {
        return;
      }

      const onParse = (event: ParsedEvent | ReconnectInterval) => {
        if (event.type === "event") {
          const data = event.data;
          try {
            const text = JSON.parse(data).text ?? "";
            setGeneratedFormula((prev) => prev + text);
          } catch (e) {
            console.error(e);
          }
        }
      };

      // https://web.dev/streams/#the-getreader-and-read-methods
      const reader = data.getReader();
      const decoder = new TextDecoder();
      const parser = createParser(onParse);
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        parser.feed(chunkValue);
      }
    } else {
      setPdfText(t("toaster.error-occurred") ?? "");
    }
  };

  useEffect(() => {
    if (generatedFormula.includes("[DONE]")) {
      setGeneratedFormula(generatedFormula.replace("[DONE]", ""));
      if (auth.currentUser?.email) {
        sendFormula(prompt, generatedFormula, ipAddress, auth.currentUser);
      } else {
        sendFormula(prompt, generatedFormula, ipAddress);
      }
    }
  }, [generatedFormula]);

  const isUnlimitedUser = async () => {
    await publicIpv4().then((ip) => setIpAddress(ip));
    if (auth.currentUser?.email) {
      const response = await fetch("/api/user/type", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: auth.currentUser,
        }),
      });

      const { isUnlimitedUser } = await response.json();

      setUnlimitedUser(isUnlimitedUser);
    }
  };

  useEffect(() => {
    isUnlimitedUser();
  }, []);
  return (
    <>
      <motion.div
        animate={{
          marginTop: ["-200px", "0px"],
          opacity: [0, 1],
          xHeight: [0, "auto"],
        }}
        viewport={{ once: true }}
        className="xl:col-span-1"
        variants={FADE_DOWN_ANIMATION_VARIANTS}
      >
        <SignInPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
        <main className="w-full">
          <div className="relative mx-auto">
            <div className="absolute -inset-2">
              <div
                className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
                style={{
                  background:
                    "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                }}
              ></div>
            </div>
            <div className="p-8 sm:px-10 relative bg-white shadow-xl rounded-2xl">
              <div className="space-y-2">
                <div className="flex w-full mx-auto flex-col items-center justify-center">
                  {!isUploaded ? (
                    <>
                      <div className="block mt-5 w-full">
                        <div className="flex mb-5 items-center space-x-3">
                          <div className="flex items-center justify-center w-8 h-8 font-black rounded-full bg-slate-900 text-white  dark:text-slate-800 dark:bg-slate-300">
                            1
                          </div>
                          <p className="text-left font-bold antialiased dark:text-slate-300">
                            {t("excel-formula-generator.first-step")}
                          </p>
                        </div>
                        <FormulaDropDown
                          formula={formula}
                          setFormula={setFormula}
                        />
                      </div>
                      <div className="block mt-5 w-full">
                        <div className="flex mb-5 items-center space-x-3">
                          <div className="flex items-center justify-center w-8 h-8 font-black rounded-full bg-slate-900 text-white  dark:text-slate-800 dark:bg-slate-300">
                            2
                          </div>
                          <p className="text-left font-bold antialiased dark:text-slate-300">
                            {t("excel-formula-generator.second-step")}
                          </p>
                        </div>
                        <LanguageDropDown
                          language={language}
                          setLanguage={setLanguage}
                        />
                      </div>
                      <textarea
                        value={formulaInputRef.current?.value}
                        ref={formulaInputRef}
                        onChange={(e) => setPrompt(e.target.value)}
                        rows={12}
                        className="w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black my-5 dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
                        placeholder={
                          t("excel-formula-generator.placeholder") ??
                          "Enter your prompt here."
                        }
                      />
                      {loading && (
                        <button
                          className="bg-slate-700 rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-slate-700 w-full dark:bg-slate-500 dark:text-slate-300 dark:hover:bg-slate-500"
                          disabled
                        >
                          <LoadingDots color="white" style="large" />
                        </button>
                      )}
                      {!loading && (
                        <button
                          className="bg-black rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-4 mt-2 hover:bg-black/80 w-full dark:bg-slate-300 dark:text-slate-800 dark:hover:bg-slate-500"
                          onClick={(e) => generateFormula(e)}
                        >
                          {t("excel-formula-generator.submit")} &rarr;
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <h1 className="text-4xl font-bold text-center mb-5 text-slate-500 dark:text-slate-300">
                        {t("excel-formula-generator.here-it-is")}
                      </h1>
                      <p className="text-lg text-center mb-2 text-slate-500 dark:text-slate-300">
                        {t("excel-formula-generator.answer-description")}
                      </p>
                    </>
                  )}
                  {loading && (
                    <button
                      className="bg-black rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-black/80 w-full"
                      disabled
                    >
                      <LoadingDots color="white" style="large" />
                    </button>
                  )}
                </div>
                <Toaster
                  position="top-center"
                  reverseOrder={false}
                  toastOptions={{ duration: 2000 }}
                />
                <ResizablePanel>
                  <AnimatePresence mode="wait">
                    <motion.div className="space-y-10 my-10">
                      {generatedFormula && (
                        <>
                          <hr className="h-px bg-gray-700 border-1 dark:bg-gray-700" />
                          <div className="space-y-4 flex flex-col items-start justify-start max-w-xl mx-auto">
                            <div
                              className="text-slate-900 text-left font-semibold antialiased flex flex-col generated-content"
                              dangerouslySetInnerHTML={{
                                __html: Marked.parse(
                                  String(generatedFormula).replace(
                                    /<br\s*[\/]?>/gi,
                                    "\n"
                                  )
                                ),
                              }}
                            ></div>
                          </div>
                        </>
                      )}
                    </motion.div>
                  </AnimatePresence>
                </ResizablePanel>
              </div>
            </div>
          </div>
        </main>
      </motion.div>
    </>
  );
}
