import { Marked } from "@ts-stack/markdown";
import FeedbackWidget from "@upstash/feedback";
import { motion, AnimatePresence } from "framer-motion";
import { t } from "i18next";
import Script from "next/script";
import toast from "react-hot-toast";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import FormArea from "../Hero/FormArea";
import UserStatusComponent from "../Hero/UserStatusComponent";
import RateIt from "../Core/RateIt";
import ResizablePanel from "./ResizablePanel";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useDailyLimit } from "../../hooks/useDailyLimit";
import { useDailyLimitCheck } from "../../hooks/useDailyLimitCheck";
import useEmailGeneration from "../../hooks/useEmailGeneration";
import { useGeneratedEmailCount } from "../../hooks/useGeneratedEmailCount";
import { useIp } from "../../hooks/useIp";
import useMixpanel from "../../hooks/useMixPanel";
import { auth } from "../../utils/Authentication";
import getRemainingTime from "../../utils/getRemainingTime";
import {
  isMessageContainsRestrictedWords,
  sendData,
} from "../../utils/main-functionality";
import { VibeType } from "../Dropdowns/DropDown";
import { Language } from "../Dropdowns/LanguageDropDown";
import { Length } from "../Dropdowns/LengthDropDown";
import { MessageType } from "../Dropdowns/MessageTypeDropDown";

export default function MessageGenerator() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [vibe, setVibe] = useState<VibeType>("Professional");
  const [language, setLanguage] = useState<Language>("English");
  const [messageType, setMessageType] = useState<MessageType>("E-mail");
  const [length, setLength] = useState<Length>("Short");
  const ipAddress = useIp();
  const emailInputRef = useRef<HTMLTextAreaElement>(null);
  const messageRef = useRef<null | HTMLDivElement>(null);
  const { hours, minutes } = getRemainingTime();
  const [isSelected, setIsSelected] = useState(false);
  const [useEmojis, setUseEmojis] = useState(false);
  const [useGPT4, setUseGPT4] = useState(false);
  const { emailCount, fetchEmailCount } = useGeneratedEmailCount();
  const { limit, isLimitLoaded, fetchDailyLimit } = useDailyLimit();
  const { userLoading, unlimitedUser, setUnlimitedUser } = useAuth();
  const { checkDailyLimit } = useDailyLimitCheck(
    ipAddress.toString(),
    unlimitedUser
  );
  const { track } = useMixpanel(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { createEmail, generatedEmail, setGeneratedEmail, isDone } =
    useEmailGeneration({
      emailInput: emailInputRef.current?.value || "",
      vibe,
      messageType,
      language,
      length,
      useEmojis,
      useGPT4,
      user: auth.currentUser?.email ?? ipAddress ?? "Anonymous",
    });

  const scrollToMessages = () => {
    if (messageRef.current !== null) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const earlyReturnConditions = async () => {
    if (!emailInputRef.current?.value) {
      return t("toaster.enter-a-prompt");
    }

    if (isMessageContainsRestrictedWords(emailInputRef.current?.value)) {
      return t("toaster.enter-a-prompt");
    }

    if (!auth.currentUser) {
      setUnlimitedUser(false);
    }

    if (await checkDailyLimit()) {
      return t("toaster.daily-limit-reached");
    }

    return null;
  };

  const generateEmail = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    setIsSelected(false);
    setGeneratedEmail("");
    e.preventDefault();

    const earlyReturnMessage = await earlyReturnConditions();
    if (earlyReturnMessage) {
      toast.error(earlyReturnMessage);
      setLoading(false);
      return;
    }

    await createEmail().finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isDone) {
      scrollToMessages();
      const eventData = {
        "Message Type": messageType,
        Language: language,
        Length: length,
        Vibe: vibe,
        "Use Emojis": useEmojis,
        User: auth.currentUser?.email ?? ipAddress ?? "Anonymous",
        "Generated Message": generatedEmail.replace("[DONE]", ""),
        Prompt: emailInputRef.current?.value,
      };

      track("Generated Email", eventData);
      sendData(
        email,
        generatedEmail.replace("[DONE]", ""),
        language,
        vibe,
        messageType,
        length,
        ipAddress,
        auth.currentUser ? auth.currentUser : undefined
      );
    }
  }, [isDone]);

  useEffect(() => {
    if (!unlimitedUser && useGPT4) {
      setUseGPT4(false);
      setIsPopupOpen(true);
    }
  }, [useGPT4]);

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <div className="relative px-4 mx-auto max-w-8xl sm:px-6 lg:px-8">
        <div className="relative mx-auto">
          <div className="absolute -inset-2">
            <motion.div
              animate={{
                opacity: 0.3,
                background: [
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                  "linear-gradient(90deg, #44b0ff -0.55%, #8b44ff 22.86%, #ff6644 48.36%, #ebff70 73.33%, #44ff9a 99.34%)",
                  "linear-gradient(90deg, #8b44ff -0.55%, #ff6644 22.86%, #ebff70 48.36%, #44ff9a 73.33%, #44b0ff 99.34%)",
                  "linear-gradient(90deg, #ff6644 -0.55%, #ebff70 22.86%, #44ff9a 48.36%, #44b0ff 73.33%, #8b44ff 99.34%)",
                  "linear-gradient(90deg, #ebff70 -0.55%, #44ff9a 22.86%, #44b0ff 48.36%, #8b44ff 73.33%, #44ff9a 99.34%)",
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                ],
              }}
              transition={{
                duration: 9,
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 0,
              }}
              className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter"
              style={{
                background:
                  "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
              }}
            ></motion.div>
          </div>
          <div className="p-8 sm:px-10 relative bg-white shadow-xl rounded-2xl">
            <UserStatusComponent
              userLoading={userLoading}
              isLimitLoaded={isLimitLoaded}
              unlimitedUser={unlimitedUser}
              limit={limit}
              hours={hours}
              minutes={minutes}
            />
            <div className="space-y-5">
              <FormArea
                emailInputRef={emailInputRef}
                setEmail={setEmail}
                messageType={messageType}
                setMessageType={setMessageType}
                vibe={vibe}
                setVibe={setVibe}
                language={language}
                setLanguage={setLanguage}
                length={length}
                setLength={setLength}
                setUseEmojis={setUseEmojis}
                useGPT4={useGPT4}
                setUseGPT4={setUseGPT4}
                loading={loading}
                generateEmail={generateEmail}
              />
              <ResizablePanel>
                {!unlimitedUser && !generatedEmail && (
                  <>
                    <hr className="h-px bg-gray-700 border-1 dark:bg-gray-700" />
                  </>
                )}

                <AnimatePresence mode="wait">
                  <motion.div className="space-y-10 my-10">
                    {generatedEmail && (
                      <>
                        <div>
                          <h2
                            className="sm:text-4xl text-3xl text-center font-bold text-slate-900 mx-auto dark:text-slate-300"
                            ref={messageRef}
                          >
                            {t("try-now.here-it-is")}
                          </h2>
                        </div>
                        <div className="space-y-4 flex flex-col items-center justify-center max-w-xl mx-auto">
                          <div
                            className="bg-white rounded-xl text-left shadow-md p-8 hover:bg-gray-100 transition cursor-copy border dark:bg-slate-300"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                String(generatedEmail).replace(
                                  /<br\s*[\/]?>/gi,
                                  "\n"
                                )
                              );
                              toast(t("toaster.copied-to-clipboard"), {
                                icon: "✂️",
                              });
                            }}
                          >
                            <div
                              className="text-slate-900 font-semibold antialiased flex flex-col generated-content"
                              dangerouslySetInnerHTML={{
                                __html: Marked.parse(
                                  String(generatedEmail).replace(
                                    /<br\s*[\/]?>/gi,
                                    "\n"
                                  )
                                ),
                              }}
                            ></div>
                          </div>
                          <div className="w-full">
                            <RateIt
                              user={ipAddress ?? "unknown"}
                              message={generatedEmail}
                              isSelected={isSelected}
                              setSelected={setIsSelected}
                            />
                          </div>
                          <div className="max-w-s w-48">
                            <button
                              className="regenerate bg-white rounded-xl text-black border-solid border-2 border-slate-800 font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-slate-200 w-full dark:bg-slate-300 dark:text-slate-800 dark:hover:bg-slate-500"
                              onClick={(e: any) => generateEmail(e)}
                            >
                              {t("try-now.regenerate")} &#x21bb;
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </motion.div>
                </AnimatePresence>
              </ResizablePanel>
              <FeedbackWidget
                showOnInitial={false}
                type="full"
                themeColor="#000000"
                textColor="#ffffff"
                title={t("feedback.title")}
                description={t("feedback.description")}
                user="."
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
