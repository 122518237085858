import React, { useState } from "react";
import CoverLetter from "../CoverLetter/CoverLetter";
import ExcelFormula from "../ExcelFormula/ExcelFormula";
import { t } from "i18next";
import AudioTranscriptor from "../AudioTranscriptor/AudioTranscriptor";
import MessageGenerator from "../MessageGenerator/MessageGenerator";

interface Segment {
  id: number;
  label: any;
}

const segments: Segment[] = [
  { id: 1, label: "header.message-generator" },
  { id: 2, label: "header.cover-letter-generator" },
  { id: 3, label: "header.excel-formula-generator" },
  { id: 4, label: "header.audio-to-text" },
];

const SegmentSelector: React.FC = () => {
  const [activeSegment, setActiveSegment] = useState<number>(segments[0].id);
  const gradients = [
    "linear-gradient(90deg, #44ff9a -0%, #44b0ff 100%)",
    "linear-gradient(90deg, #8b44ff -0%, #ff6644 100%)",
    "linear-gradient(90deg, #ebff70 -0%, #44ff9a 100%)",
    "linear-gradient(90deg, #ff449a -0%, #44ff9a 100%)",
  ];
  const handleSegmentClick = (id: number) => {
    setActiveSegment(id);
  };

  return (
    <div>
      <div className="grid grid-cols-2 w-full gap-4 p-4 mx-auto">
        {segments.map((segment) => (
          <>
            <div className="relative">
              <div className="absolute -inset-2">
                <div
                  className="w-full h-2 mx-auto rotate-180 opacity-30 blur-lg filter"
                  style={{
                    background: gradients[segment.id - 1],
                  }}
                ></div>
              </div>
              <button
                key={segment.id}
                className={`font-semibold relative px-4 py-2 shadow-xl rounded-2xl text-center w-full ${
                  activeSegment === segment.id
                    ? "bg-teal-700 text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => handleSegmentClick(segment.id)}
              >
                {t(segment.label)}{" "}
                {segment.label.includes("cover") && (
                  <div className="absolute text-xs top-0 h-full right-0 bg-green-500 text-white px-2 py-1 rounded-tr-2xl rounded-br-2xl items-center flex">
                    {t("hero.free")}
                  </div>
                )}
              </button>
            </div>
          </>
        ))}
      </div>
      <div className="p-4 mt-8">
        {activeSegment === 1 && <MessageGenerator />}
        {activeSegment === 2 && <CoverLetter />}
        {activeSegment === 3 && <ExcelFormula />}
        {activeSegment === 4 && <AudioTranscriptor />}
      </div>
    </div>
  );
};

export default SegmentSelector;
