import { motion } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS, FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { t } from "i18next";

interface UserStatusProps {
  userLoading: boolean;
  isLimitLoaded: boolean;
  unlimitedUser: boolean;
  limit: number;
  hours: number;
  minutes: number;
}

const UserStatusComponent: React.FC<UserStatusProps> = ({ userLoading, isLimitLoaded, unlimitedUser, limit, hours, minutes }) => {
  return (
    <>
      {!userLoading && isLimitLoaded ?
        <div className="max-w-xl mx-auto text-center">
          {unlimitedUser ?
            <motion.h2 variants={FADE_UP_ANIMATION_VARIANTS} className="text-4xl font-bold text-gray-900 font-pj">
              {t('try-now.unlimited-user')}
            </motion.h2> : <>
              <motion.h2 variants={FADE_UP_ANIMATION_VARIANTS} className="text-4xl font-bold text-gray-900 font-pj">{t('try-now.free-user', { limit })}</motion.h2>
              <motion.p variants={FADE_DOWN_ANIMATION_VARIANTS} className="max-w-md mx-auto mt-5 text-base font-normal text-gray-600 font-pj">{t('try-now.free-user-time-limit', { hours, minutes })}</motion.p>
            </>}
        </div> :
        <div className="animate-pulse">
          <div className="flex items-center justify-center">
            <div className="w-9/12 h-6 bg-gray-300 rounded-full"></div>
          </div>
          <div className="flex items-center justify-center mt-5">
            <div className="w-9/12 h-6 bg-gray-300 rounded-full"></div>
          </div>
          <div className="flex items-center justify-center mt-12">
            <div className="w-9/12 h-3 bg-gray-300 rounded-full"></div>
          </div>
        </div>
      }
    </>
  );
}

export default UserStatusComponent;
