import { useState, useEffect } from "react";
import { auth } from "../utils/Authentication";
import { isUnlimitedUser } from "../utils/main-functionality";

export const useAuth = () => {
    const [userLoading, setUserLoading] = useState(true);
    const [unlimitedUser, setUnlimitedUser] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user?.email) {
                isUnlimitedUser(user.email).then((res) => {
                    setUnlimitedUser(Boolean(res));
                    setUserLoading(false);
                })
            }
            setUserLoading(false);
        });
    }, []);

    return { userLoading, unlimitedUser, setUnlimitedUser };
};