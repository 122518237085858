import { Toaster, toast } from "react-hot-toast";
import MainLeft from "./MainLeft";
import SegmentSelector from "./SegmentSelector";

export default function Hero() {
  return (
    <div className="bg-gray-50">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 2000 }}
      />
      <section className="relative py-12 sm:py-16 lg:pb-40">
        <div className="absolute bottom-0 right-0 overflow-hidden">
          <img
            className="w-full h-auto origin-bottom-right transform scale-150 lg:w-auto lg:mx-auto lg:object-cover lg:scale-75"
            src="/background-pattern-2.png"
            alt=""
          />
        </div>
        <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-4 gap-x-8 lg:items-start lg:grid-cols-2 xl:grid-cols-2">
            <MainLeft />
            <SegmentSelector />
          </div>
        </div>
      </section>
    </div>
  );
}
