import { useState, useCallback } from 'react';
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
  region: process.env.NEXT_PUBLIC_AWS_REGION,
});

type UseS3UploadReturnType = {
  isUploadLoading: boolean;
  s3Url: string | null;
  uploadError: Error | null;
  uploadToS3: (file: File, path?: string) => Promise<void>;
};

export const useS3Upload = (): UseS3UploadReturnType => {
  const [isUploadLoading, setIsLoading] = useState(false);
  const [s3Url, setS3Url] = useState<string | null>(null);
  const [uploadError, setError] = useState<Error | null>(null);

  const generatePresignedUrl = async (bucket: string, key: string) => {
    const params = { Bucket: bucket, Key: key, Expires: 60 };
    const url = await new Promise<string>((resolve, reject) => {
      s3.getSignedUrl("getObject", params, (err, url) => {
        if (err) reject(err);
        else resolve(url);
      });
    });
    return url;
  };

  const uploadToS3 = useCallback(async (file: File, path?: string) => {
    setIsLoading(true);
    const filePath = path ?? '/';
    const params = {
      Bucket: 'professionalizeittome',
      Key: `${filePath}/${file.name}`,
    };

    try {
      const url = await generatePresignedUrl(params.Bucket, params.Key);
      s3.putObject({ ...params, Body: file }).promise().then(() => {
        setS3Url(url);
        setIsLoading(false);
      });
    } catch (uploadError: any) {
      setError(uploadError);
    }
  }, []);

  return {
    isUploadLoading,
    s3Url,
    uploadError,
    uploadToS3,
  };
};
