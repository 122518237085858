import { ParsedEvent, ReconnectInterval, createParser } from 'eventsource-parser';  // Your import might be different

interface GenerateEmailServiceProps {
  emailInput: string;
  vibe: string;
  messageType: string;
  language: string;
  length: string;
  useEmojis: boolean;
  useGPT4: boolean;
  user: string | null;
  onParse: (event: ParsedEvent | ReconnectInterval) => void;
}

export const generateEmailService = async ({ emailInput, vibe, messageType, language, length, useEmojis, useGPT4, user, onParse }: GenerateEmailServiceProps) => {
  const response = await fetch('/api/generate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: emailInput,
      vibe,
      messageType,
      language,
      length,
      useEmojis,
      useGPT4,
      user,
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const data = response.body;
  if (!data) {
    throw new Error('No data received');
  }

  const reader = data.getReader();
  const decoder = new TextDecoder();
  const parser = createParser(onParse);

  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);
    parser.feed(chunkValue);

    if (done) {
      return 'Done';
    }
  }
};
