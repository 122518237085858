import { Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { Fragment, useMemo } from "react";
import { t } from "i18next";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type MessageType = "E-mail" | "Slack Message" | "Linkedin Message" | "Linkedin Post" | "WhatsApp Message" | "Summarized";

interface MessageTypeDropDownProps {
  messageType: MessageType;
  setMessageTypes: (messageType: MessageType) => void;
}

const messageTypes: MessageType[] = ["E-mail", "Slack Message", "Linkedin Message", "Linkedin Post", "WhatsApp Message", "Summarized"];

export default React.memo(function MessageTypeDropDown({ messageType, setMessageTypes }: MessageTypeDropDownProps) {
  const menuItems = useMemo(
    () =>
      messageTypes.map((messageTypeItem) => (
        <Menu.Item key={messageTypeItem}>
          {({ active }) => (
            <button
              onClick={() => setMessageTypes(messageTypeItem)}
              className={classNames(
                active ? "bg-gray-100 text-gray-900 dark:bg-slate-600 dark:text-slate-300" : "text-gray-700",
                messageType === messageTypeItem ? "bg-gray-200 dark:bg-slate-600 dark:text-slate-300" : "",
                "px-4 py-2 text-sm w-full text-left flex items-center space-x-2 justify-between dark:text-slate-300"
              )}
            >
              <span
                className={classNames(
                  messageType === messageTypeItem ? "font-medium" : "font-normal",
                  "truncate"
                )}
              >
                {t(`dropdown.${messageTypeItem.toLowerCase().replace(" ", "-")}`)}
              </span>
              <div className="ml-4 flex-row flex">
              {messageType === messageTypeItem ? (
                <CheckIcon
                  className="ml-2 h-5 w-5 text-slate-500"
                  aria-hidden="true"
                />
              ) : null}
              </div>
            </button>
          )}
        </Menu.Item>
      )),
    [messageType, setMessageTypes]
  );

  return (
    <Menu as="div" className="relative block text-left w-full">
      <div>
      <Menu.Button className="inline-flex w-full justify-between items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black dark:border-gray-800 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-500 dark:text-slate-300">
          {messageType}
          <ChevronUpIcon
            className="-mr-1 ml-2 h-5 w-5 ui-open:hidden"
            aria-hidden="true"
          />
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 hidden ui-open:block"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700"
          key={messageType}
        >
          <div className="">{menuItems}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
});