import { useEffect } from 'react';
import { initMixpanel, trackEvent } from '../utils/mixpanelHelper';

const useMixpanel = (key: string, events?: { [key: string]: any }) => {
  useEffect(() => {
    initMixpanel(key);
  }, [key]);

  const track = (eventName: string, eventData: { [key: string]: any }) => {
    trackEvent(eventName, eventData);
  };

  useEffect(() => {
    if (events) {
      Object.keys(events).forEach((eventName) => {
        trackEvent(eventName, events[eventName]);
      });
    }
  }, [events]);

  return { track };
};

export default useMixpanel;
