import { useState, useEffect } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { useS3Upload } from "../../hooks/useS3Upload";
import { publicIpv4 } from "public-ip";
import {
  isTranscriptionLimitExceeded,
  isUnlimitedUser,
} from "../../utils/main-functionality";
import { auth } from "../../utils/Authentication";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import useMixpanel from "../../hooks/useMixPanel";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import LoadingDots from "../Core/LoadingDots";
import { CreditDisplay } from "../../components/Transcription/CreditDisplay";
import { DefaultTFuncReturn, t } from "i18next";

export default function AudioTranscriptor() {
  const { isUploadLoading, s3Url, uploadError, uploadToS3 } = useS3Upload();
  const [translateChecked, setTranslateChecked] = useState(false);
  const [srtRequested, setSrtRequested] = useState(false);
  const [clientIp, setClientIp] = useState<string | null>(null);
  const [unlimitedUser, setUnlimitedUser] = useState(false);
  const [dropText, setDropText] = useState<DefaultTFuncReturn | string>(
    t("audio-transcriptor.drop-text")
  );
  const { track } = useMixpanel(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");
  const { isLoading, response, transcribeAudio } = useAudioTranscription();

  const cleanFileName = (fileName: string) => {
    const [cleanName] = fileName.split("?");
    return cleanName;
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file.size > 3145728) {
      toast(t("audio-transcriptor.too-large"), { icon: "⚠️" });
      return;
    }

    if (
      file.type.includes("audio") &&
      file.name.match(/\.(mp3|mp4|m4a|wmv|wav|ogg|flac)$/i)
    ) {
      await uploadToS3(file, "transcription");
      const ip = await publicIpv4();
      setClientIp(ip);
    } else {
      toast.dismiss();
      toast(t("audio-transcriptor.unsupported-file"), { icon: "⚠️" });
    }
  };

  useEffect(() => {
    const fetchClientIp = async () => {
      try {
        const ip = await publicIpv4();
        setClientIp(ip);
      } catch {
        setTimeout(fetchClientIp, 1000);
      }
    };

    fetchClientIp();

    auth.onAuthStateChanged(async (user) => {
      if (user?.email) {
        const res = await isUnlimitedUser(user.email);
        setUnlimitedUser(Boolean(res));
      }
    });
  }, []);

  useEffect(() => {
    const handleTranscription = async () => {
      if (!clientIp || !s3Url) return;

      const isDailyLimitExceeded = await isTranscriptionLimitExceeded(clientIp);
      if (isDailyLimitExceeded && !unlimitedUser) {
        toast.error(t("toaster.daily-limit-reached"));
        return;
      }

      transcribeAudio(s3Url, translateChecked, srtRequested);
      track("Audio Transcription", {
        "Audio Transcription": "Success",
        url: s3Url,
        transcription: response?.text ?? "",
      });
    };

    handleTranscription();
  }, [s3Url]);

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <main className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center py-12">
          {uploadError && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8"
              role="alert"
            >
              <span className="block sm:inline">
                {uploadError.message?.toString()}
              </span>
            </div>
          )}
          {clientIp && !unlimitedUser && <CreditDisplay ip={clientIp} />}
          {!isLoading && !isUploadLoading && (
            <>
              <Dropzone
                onDrop={handleDrop}
                onDragOver={(e) => {
                  e.preventDefault();
                  setDropText(
                    t("audio-transcriptor.drop-your-audio-file-here")
                  );
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setDropText(t("audio-transcriptor.drop-text"));
                }}
                accept={
                  {
                    "audio/*": [
                      ".mp3",
                      ".mp4",
                      ".m4a",
                      ".wmv",
                      ".wav",
                      ".ogg",
                      ".flac",
                    ],
                  } as Accept
                }
                onDropRejected={() => {
                  toast(t("audio-transcriptor.unsupported-file"), {
                    icon: "⚠️",
                  });
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="cursor-pointer border h-24 flex flex-col justify-center items-center rounded-md border-dashed border-gray-800 dark:border-gray-500"
                  >
                    <input {...getInputProps()} />
                    <p className="text-lg p-12 text-center text-slate-500 dark:text-slate-300">
                      {dropText}
                    </p>
                  </div>
                )}
              </Dropzone>
              <div className="flex flex-col mt-5 items-center justify-center bg-white rounded-xl shadow-md p-4 hover:bg-gray-50 transition border dark:bg-slate-300">
                <p className="text-xs text-center mt-3 font-medium justify-center items-center ml-2">
                  {t("audio-transcriptor.config")}
                </p>
                <div className="flex-row items-center justify-center self-center py-4">
                  <input
                    type="checkbox"
                    id="translate"
                    className="rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
                    name="translate"
                    checked={translateChecked}
                    onChange={() => setTranslateChecked(!translateChecked)}
                  />
                  <label
                    htmlFor="translate"
                    className="text-xs text-center font-pj justify-center items-center ml-2"
                  >
                    {t("audio-transcriptor.translate")}
                  </label>
                  <input
                    type="checkbox"
                    id="srt"
                    className="ml-5 rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black dark:bg-slate-600 dark:placeholder:text-slate-400 dark:border-gray-500 dark:text-slate-300 dark:focus:ring-slate-300"
                    name="srt"
                    checked={srtRequested}
                    onChange={() => setSrtRequested(!srtRequested)}
                  />
                  <label
                    htmlFor="srt"
                    className="text-xs text-center font-pj justify-center items-center ml-2"
                  >
                    {t("audio-transcriptor.srt")}
                  </label>
                </div>
              </div>
            </>
          )}
          {(isLoading || isUploadLoading) && (
            <button
              className="bg-black rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-black/80 max-w-xs w-full"
              disabled
            >
              {isLoading
                ? t("audio-transcriptor.processing")
                : t("audio-transcriptor.uploading")}
              <LoadingDots color="white" style="large" />
            </button>
          )}
          {response?.srtUrl && (
            <a
              className="bg-black cursor-pointer text-center rounded-xl text-white font-bold antialiased px-4 py-2 sm:mt-10 mt-8 hover:bg-black/80 max-w-xs w-full"
              onClick={async () => {
                try {
                  const filename = decodeURIComponent(
                    cleanFileName(response.srtUrl?.split("/").pop() || "")
                  );
                  const res = await fetch(response.srtUrl?.toString() || "");
                  const blob = await res.blob();
                  const url = window.URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } catch (error) {
                  console.error(
                    "An error occurred while downloading the file",
                    error
                  );
                }
              }}
            >
              {t("audio-transcriptor.srt")}
            </a>
          )}

          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{ duration: 2000 }}
          />
          <ResizablePanel>
            <AnimatePresence mode="wait">
              <motion.div className="space-y-10 my-10">
                {response && !response?.srtUrl && (
                  <div className="space-y-4 flex flex-col items-center justify-center max-w-xl mx-auto">
                    <div
                      className="bg-white rounded-xl text-left shadow-md p-8 hover:bg-gray-100 transition cursor-copy border dark:bg-slate-300"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          String(response.text).replace(/<br\s*[\/]?>/gi, "\n")
                        );
                        toast(t("audio-transcriptor.copy"), { icon: "✂️" });
                      }}
                    >
                      <div className="text-slate-900 font-semibold antialiased flex flex-col generated-content">
                        <p>{response.text}</p>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>
          </ResizablePanel>
        </div>
      </main>
    </motion.div>
  );
}
